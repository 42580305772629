$(document).ready(function () {
    $('.search-cat').select2({
        placeholder: "選擇分類"
    });

    $('#disabled-days').datepicker({
        language: 'en',
        onRenderCell: function (date, cellType) {
            if (cellType == 'day') {
                var day = date.getDay(),
                    isDisabled = disabledDays.indexOf(day) != -1;

                return {
                    disabled: isDisabled
                }
            }
        }
    });

    $(".searchtyping").focus(function (e) {
        $(".searchrecommend").show();
    })
        .blur(function (e) {
            setTimeout(function () {
                $(".searchrecommend").hide();
            }, 2500);
        });


    /*$(".sub-type").hover(function() {
      $(this).find('.sub-follow').removeClass('d-none');
    },function() {
        $(this).find('.sub-follow').addClass('d-none');
    });*/


    $("a[href='#top']").click(function () {
        $("html, body").animate({scrollTop: 0}, "slow");
        return false;
    });

    $('.mobile-menu-toggle').click(function (e) {
        e.preventDefault();
        $('#menu-mobile').toggleClass('active');
        if ($('#menu-mobile').hasClass('active')) {
            $('body').addClass('full-screen-popup-mobile');
        } else {
            $('body').removeClass('full-screen-popup-mobile');
        }
    });
    $('.icon-down-right .fa-angle-up').hide();
    $('.icon-down-right-shop .fa-angle-up').hide();
    $('a#game').click(function (e) {
        e.preventDefault();
        if ($('.mob-main-nav-sub').hasClass('active')) {
            $('.icon-down-right .fa-angle-up').hide();
            $('.icon-down-right .fa-angle-down').show();
        } else {
            $('.icon-down-right .fa-angle-up').show();
            $('.icon-down-right .fa-angle-down').hide();
        }
        $('.mob-main-nav-sub').toggleClass('active');
    });
    $('a#shop_info').click(function (e) {
        e.preventDefault();
        if ($('.mob-main-nav-sub-shop').hasClass('active')) {
            $('.icon-down-right-shop .fa-angle-up').hide();
            $('.icon-down-right-shop .fa-angle-down').show();
        } else {
            $('.icon-down-right-shop .fa-angle-up').show();
            $('.icon-down-right-shop .fa-angle-down').hide();
        }
        $('.mob-main-nav-sub-shop').toggleClass('active');
    });

    $('.search-adv-btn').click(function (e) {
        e.preventDefault();
        $('#search-adv').toggleClass('active');
        if ($('#search-adv').hasClass('active')) {
            $('body').addClass('full-screen-popup');
        } else {
            $('body').removeClass('full-screen-popup');
        }

    });
});

