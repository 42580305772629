import "@babel/polyfill";
import Vue from "vue";
import VueCookies from "vue-cookies";
import axios from "axios";
import debounce from "lodash/debounce";

global.$ = global.jQuery = window.jQuery = window.$ = require('jquery');
import "jquery.scrollto";

import "bootstrap";

global.Swiper = global.swiper = window.Swiper = window.swiper = require('swiper/dist/js/swiper');

import select2 from "select2";
// import "select2/dist/css/select2.css";

import "../common/js/datepicker.js";
// import "../common/css/datepicker.css";
import "../common/js/i18n/datepicker.en.js";

import "../common/js/r-slider";

import "../common/js/common.js";